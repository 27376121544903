if (document.querySelectorAll('[data-component="carousel"]').length > 0) {

    window.addEventListener('DOMContentLoaded', function () {

        $('#slickBanner').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: false,
            nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg></div>',
			prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg></div>'
        })

        $('#slickProduct').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            centerMode: true,
            nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg></div>',
			prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg></div>',
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                    }
                }
            ]
        })

        $('#slickProductDetail').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: false,
            nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg></div>',
			prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg></div>'
        })

        $('#slickCategory').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            centerMode: true,
            variableWidth: true,
            nextArrow: '<div class="slick-right flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg></div>',
			prevArrow: '<div class="slick-left flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg></div>',
            responsive: [{
                breakpoint: 1024,
                settings: {
                    variableWidth: false,
                }
            }]
        })

        $('.slick').fadeIn();
    })
}